<template>
  <base-loader v-if="this.$store.state.moduleStack.rules.length === 0" />
  <base-table-row
    v-else
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #direction>
      <div class="tariffs-table-item__link medium-text">
        {{ item.direction === 'egress' ? 'Исходящий трафик' : 'Входящий трафик' }}
      </div>
      <div class="tariffs-table-item__specs note-text">
        {{ item.description }}
      </div>
    </template>
    <template #type>
      <div class="tariffs-table-item__ip">
        {{ item.ethertype }}
      </div>
    </template>
    <template #ip>
      <div class="tariffs-table-item__ip">
        {{ item.protocol === null ? 'Любой' : item.protocol }}
      </div>
    </template>
    <template #port>
      <div class="tariffs-table-item__ip">
        {{ port(item) }}
      </div>
    </template>
    <template #prefix>
      <div class="tariffs-table-item__ip">
        {{ item.remote_ip_prefix }}
      </div>
    </template>
    <!--    <template #group>-->
    <!--      <div class="tariffs-table-item__ip">-->
    <!--        {{ item.remote_group_id }}-->
    <!--        {{ group(item) }}-->
    <!--      </div>-->
    <!--    </template>-->
    <!--    <template #description>-->
    <!--      <div class="tariffs-table-item__ip">-->
    <!--        {{ item.description }}-->
    <!--      </div>-->
    <!--    </template>-->
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button
          icon="more"
          class="tariffs-table-item__more"
          @click="togglePopoverState"
        /><template #popover>
          <rules-context-menu
            :tariff="item"
            :firewall="firewall"
            class="tariff-table-item__context-menu"
        /></template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import RulesContextMenu from './RulesContextMenu';
import popoverFix from '@/mixins/popoverFix';
export default {
  name: 'TariffsTableRulesViewRow',
  components: {
    BaseTableRow,
    RulesContextMenu,
  },
  mixins: [popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    firewall: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tcp_protocols: {
        DNS: 53,
        HTTP: 80,
        HTTPS: 443,
        IMAP: 143,
        IMAPS: 993,
        LDAP: 389,
        MS_SQL: 1433,
        MYSQL: 3306,
        POP3: 110,
        POP3S: 995,
        RDP: 3389,
        SMTP: 25,
        SMTPS: 465,
        SSH: 22,
      },
    };
  },
  computed: {
    rule() {
      return this.$store.state.moduleStack.rules.find(x => x.security_group_id === this.firewall);
    },
  },
  methods: {
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    port(item) {
      if (item.port_range_min === item.port_range_max) {
        if (item.port_range_max === null) return 'Любой';
        else {
          const port = `${item.port_range_min} (${this.getKeyByValue(
            this.tcp_protocols,
            item.port_range_min
          )})`;
          return Object.values(this.tcp_protocols).includes(item.port_range_min)
            ? port
            : item.port_range_max;
        }
      } else return `${item.port_range_min} - ${item.port_range_max}`;
      // return `${item.port_range_min} - ${item.port_range_max}`;
    },
    group(item) {
      return item.remote_group_id
        ? this.$store.state.moduleStack.firewalls.find(x => x.id === item.remote_group_id).name
        : null;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "gb": "{num} ГБ",
    "sure": {
      "confirm": "Подключить",
      "success": "Правило добавлено успешно",
      "fail": "Что-то пошло не так",
      "close": "Хорошо"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: ellipsis;
      //text-overflow: unset;
      //overflow: visible;
      overflow: hidden;
      z-index: 2;
    }
  }
  &__sub{
    margin-top: 0.5rem;
  }
  &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
