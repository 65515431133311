<template>
  <div class="stack-context-menu">
    <template v-if="isSuspended">
      <div class="stack-context-menu__noact standart-text">
        <div>{{ $t('noact.noactions') }}</div>
      </div>
    </template>
    <template v-else>
      <div v-for="(group, i) in menu" :key="i" class="stack-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="stack-context-menu__item standart-title"
          :class="{ [`stack-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
  </div>
</template>

<script>
import showErrorModal from '@/mixins/showErrorModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import renamePool from '@/mixins/renamePool';
import replaceSSL from '@/mixins/replaceSSL';
import changePoolDescription from '@/layouts/Stack/mixins/changePoolDescription';
export default {
  name: 'SslContextMenu',
  mixins: [showErrorModal, replaceSSL],
  props: {
    listener: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      res: '',
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
      snapId: '',
      imageId: '',
    };
  },
  computed: {
    balancer() {
      return this.$store.state.moduleStack.balancers.find(
        x => x.id === this.listener.loadbalancers[0].id
      );
    },
    // listener() {
    //   return this.$store.state.moduleStack.listeners.find(
    //     x => x.id === this.tariff.listeners[0].id
    //   );
    // },

    isSsl() {
      return (
        !!this.listener.default_tls_container_ref &&
        !!this.listener.default_tls_container_ref.length
      );
    },
    isEnabledSsl() {
      return this.listener.protocol === 'TERMINATED_HTTPS';
    },
    isSuspended() {
      return (
        this.balancer.provisioning_status.key === 'PENDING_DELETE' ||
        this.balancer.provisioning_status.key === 'PENDING_CREATE'
      );
    },
    menu() {
      const arr = [];
      arr.push([
        {
          key: 'ssl',
          id: '',
          handler: 'ssl',
          action: 'ssl',
        },
      ]);
      return arr;
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      // if (item.action && item.handler === 'delete') this.deletePool(item);
      if (item.action && item.handler === 'ssl') this.replaceSSL(this.listener);
      // if (item.action && item.key === 'edit') this.renamePool(this.tariff.name, item);
      // if (item.handler === 'descr') this.changePoolDescription(this.tariff);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "delete": "Вы уверены, что хотите удалить правило:",
    "actions": {
      "edit": "Переименовать правило",
      "false": "Выключить",
      "true": "Включить",
      "ssl": "Заменить SSL",
      "process": "обновляем...",
      "restart": "перезагрузить",
      "dhcp": "Включить|Выключить DHCP",
      "delete": "Удалить правило",
      "descr": "Изменить описание",
      "description": "Описание правила"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена",
      "title": "Увеличить размер диска"
    },
    "noact": {
      "noactions": "Нет доступных действий.",
      "suspended": "Услуга остановлена администратором."
    },
    "sure": {
      "confirm": "Подтвердить"
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg} правило. Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "true": "включена",
        "false": "выключена",
        "success": {
          "true": "Сеть успешно включена",
          "false": "Сеть успешно выключена",
          "delete" : "Правило удаляется"
        },
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>
<!--//        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",-->

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.stack-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }

  &__noact {
    div + div {
      margin-top: 0.75rem;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.stack-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
